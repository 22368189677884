import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { trigger, transition, animate, style, state } from '@angular/animations'

import { Usermodel } from '../../models/usermodel';

import { AccountService } from '../../services/account.service';

import { Clientapphelper } from '../../helpers/clientapphelper';

import { DialogComponent as errorDialog } from 'app/core/login/dialog/dialog.component';

@Component({
  selector: 'fury-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('zoomInOut', [
      transition(':enter', [
        style({
          'opacity': 0.0,
          'transform': 'scale(0, 0)'
        }),
        animate('5000ms ease')
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  inputType = 'password';
  visible = false;

  state: string;

  user = new Usermodel();

  message: string;
  style: string;
  isMessageHidden: boolean;
  isLoading: boolean;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private accountService: AccountService,
    private clientapphelper: Clientapphelper
  ) {
    this.isMessageHidden = true;
    this.isLoading = false;
  }

  ngOnInit() {
    this.state = 'onEnter';

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  show() {
    this.inputType = 'text';
    this.visible = true;
    this.cd.markForCheck();
  }

  hide() {
    this.inputType = 'password';
    this.visible = false;
    this.cd.markForCheck();
  }

  // ngOnInit() {
  // }

  onLogin() {
    // this.router.navigateByUrl('admin');
    var self = this;
    this.isLoading = true;

    this.user.username = this.form.value.username;
    this.user.password = this.form.value.password;

    this.accountService
      .login(this.user)
      .subscribe(
        response => {
          this.isMessageHidden = false;
          this.message = 'Authentication successful.';
          this.style = 'alert alert-success alert-dismissible fade show';

          setTimeout(function () {
            this.isLoading = false;

            self.router.navigateByUrl('admin');
          }, 3000);
        },
        (error: any) => {
          console.log(error);
          this.isLoading = false;

          const alert = this.dialog.open(errorDialog, {
            data: {
              message: 'Invalid user.'
            }
          });

          alert.afterClosed().subscribe(result => {
          });
        }
      );
  }

}
