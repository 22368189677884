import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Clientapphelper } from 'app/helpers/clientapphelper';
import { catchError } from 'rxjs/operators';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
      private clientAppHelper: Clientapphelper, 
      private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (localStorage.getItem('accessToken')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'x-clientid': this.clientAppHelper.getClientApp()
                },
                withCredentials: false
            });
        }

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.router.navigateByUrl('login');
            } else if (err.status === 200 && err.message.includes('Http failure during parsing for https:')) {
                // // some weird shits.
                // this.authenticationService.logout();
                // location.reload(true);
            } else if (err.status === 503) {
                // some weird shits.
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
  }
}
