import { Injectable } from '@angular/core';;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Apiurlhelper } from '../helpers/apiurlhelper';
import { Clientapphelper } from '../helpers/clientapphelper';
import { Errorhelper } from '../helpers/errorhelper';

import { Usermodel } from '../models/usermodel';
import { Responsemodel } from '../models/responsemodel';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  checkExistingUser() {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp()
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + "api/account/setup",
        null,
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => {
          if (response.status === true)
            localStorage.setItem('isExistingUser', '0');
          else if (response.status === false)
            localStorage.setItem('isExistingUser', '1');

          return response;
        }),
        catchError((error: any) => this.errorHelper.handleError(error))
      );
  }

  adminRegistration(usermodel: Usermodel): Observable<Responsemodel> {
    usermodel.clientid = this.clientAppHelper.getClientApp();

    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json'
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + "api/account/admin/register",
        JSON.stringify(usermodel),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: any) => this.errorHelper.postError(error))
      );
  }

  userExists(): boolean {
    let userExist = localStorage.getItem('isExistingUser');
    let status = parseInt(userExist);

    if (status === 0)
      return false;
    
    if (status === 1)
      return true;
  }
}
