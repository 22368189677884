import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AccountService } from 'app/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class UserloggedinguardGuard implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isLoggedIn = this.accountService.isUserLoggedIn();
    
    if (isLoggedIn === true) {
      this.router.navigateByUrl('basix/patient/list');
    }
    else {
      this.router.navigateByUrl('login');
      return false;
    }
  }

}
