import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';


import 'hammerjs'; // Needed for Touch functionality of Material Components
import { environment } from '../environments/environment';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

// HELPER IMPORTS
import { Apiurlhelper } from './helpers/apiurlhelper';
import { Clientapphelper } from './helpers/clientapphelper';
import { Errorhelper } from './helpers/errorhelper';
import { JwtInterceptor } from './helpers/jwt.interceptor';

// SERVICES IMPORTS
// import { AccountService } from 'app/services/account.service';
import { TestService } from './services/test.service';

// MODULE IMPORTS
import { StartupModule } from './core/startup/startup.module';
import { SetupModule } from './core/setup/setup.module';
import { LoginModule } from './core/login/login.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular Core Module // Don't remove!
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // CUSTOM MODULE(S)
    StartupModule,
    SetupModule,
    LoginModule,

    // Fury Core Modules
    CoreModule,
    RoutingModule,

    // Register a Service Worker (optional)
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    Apiurlhelper,
    Clientapphelper,
    Errorhelper,
    // AccountService,
    TestService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
