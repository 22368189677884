import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Usermodel } from '../../models/usermodel';
import { Responsemodel } from '../../models/responsemodel';

import { SetupService } from '../../services/setup.service';

import { Clientapphelper } from '../../helpers/clientapphelper';

@Component({
  selector: 'fury-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  user = new Usermodel();

  inputType = 'password';

  message: string;
  style: string;
  isHidden: boolean;

  constructor(
    private setupService: SetupService,
    private router: Router,
    private clientapphelper: Clientapphelper
  ) { 
    this.isHidden = true;
  }

  ngOnInit() {
  }

  onRegister() {
    this.user.clientid = this.clientapphelper.getClientApp();

    var self = this;

    this.setupService
      .adminRegistration(this.user)
      .subscribe(
        result => {
          if (result.status === true) {
            this.isHidden = false;
            this.message = result.message;
            this.style = "alert alert-success alert-dismissible fade show";

            setTimeout(function () {
              self.router.navigateByUrl('login');
            }, 3000);
          }
        },
        (error: Responsemodel) => {
          this.isHidden = false;
          this.message = error.message;
          this.style = "alert alert-warning alert-dismissible fade show";

          setTimeout(function () {
            this.isHidden = true;
            console.log(error);
          }, 3000);
        }
      );
  }

}
