import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../common/material-components.module'
import { FuryCardModule } from '../common/card/card.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginComponent } from './login.component'
import { DialogComponent } from 'app/core/login/dialog/dialog.component';

// MODULE IMPORTS

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FuryCardModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  declarations: [
    LoginComponent,
    DialogComponent
  ],
  exports: [LoginComponent],
  entryComponents: [DialogComponent]
})
export class LoginModule { }
