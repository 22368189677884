import { HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { Responsemodel } from "../models/responsemodel";

export class Errorhelper {
  public loginError(error: HttpErrorResponse): Observable<Responsemodel> {
    let response = new Responsemodel();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      response.status = false;
      response.message = "Client Error: " + error.error.message;
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      response.status = false;
      response.message = "Server Error " + error.message;

      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error} ` +
      //   `Error: ${error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(response);
  }
  
  public handleError(error: HttpErrorResponse): Observable<Responsemodel> {
    let response = new Responsemodel();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      response.status = false;
      response.message = "Client Error: " + error.error.message;
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      response.status = false;
      response.message = "Server Error " + error.message;

      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error} ` +
      //   `Error: ${error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(response);
  }

  public fetchError(error: HttpErrorResponse) {
    let response = new Responsemodel();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      response.status = false;
      response.message = "Client Error: " + error.error.message;
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      response.status = false;
      response.message = "Server Error: " + error.error.message;

      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error} ` +
      //   `Error: ${error.message}`);
    }
    // return an observable with a user-facing error message
    console.log(response);
    return throwError(error);
  }

  public postError(error: HttpErrorResponse): Observable<Responsemodel> {
    let response = new Responsemodel();
    
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      response.status = false;
      response.message = "Client Error: " + error.error.message;
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      response.status = false;
      response.message = "Server Error: " + error.error.message;
    }

    return throwError(response.message);
  }
}
