import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../common/material-components.module';

import { SetupComponent } from './setup.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [SetupComponent],
  exports: [SetupComponent]
})
export class SetupModule { }
