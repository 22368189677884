import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Apiurlhelper } from '../helpers/apiurlhelper';
import { Clientapphelper } from '../helpers/clientapphelper';
import { Errorhelper } from '../helpers/errorhelper';

import { Usermodel } from '../models/usermodel';
import { Tokenmodel } from '../models/tokenmodel';
import { Responsemodel } from '../models/responsemodel';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private apiHelper: Apiurlhelper,
    private clientAppHelper: Clientapphelper,
    private errorHelper: Errorhelper,
    private httpClient: HttpClient
  ) { }

  login(user: Usermodel) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json'
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/token',
        JSON.stringify(user),
        { headers: headers }
      )
      .pipe(
        map((response: Tokenmodel) => {
          // login successful if there's a jwt token in the response
          if (response && response.accessToken) {
            localStorage.setItem('accessToken', response.accessToken);
            localStorage.setItem('currentUserId', response.userId);
            localStorage.setItem('user', response.email);
            // localStorage.setItem('role', response.role.toString());
          }

          return response;
        }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  addUser(user: Usermodel): Observable<Responsemodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .post(
        this.apiHelper.getApiUrl() + 'api/v1/account/register',
        JSON.stringify(user),
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  getUsers(): Observable<Usermodel[]> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/account/users',
        { headers: headers }
      )
      .pipe(
        map((response: Usermodel[]) => { return response })
      );
  }

  getUserInfo(id: number): Observable<Usermodel> {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .get(
        this.apiHelper.getApiUrl() + 'api/account/user/' + id + '/info',
        { headers: headers }
      )
      .pipe(
        map((response: Usermodel) => { return response })
      );
  }

  deleteUser(id: number) {
    let headers = new HttpHeaders({
      'x-clientid': this.clientAppHelper.getClientApp(),
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
    });

    return this.httpClient
      .delete(
        this.apiHelper.getApiUrl() + 'api/account/user/' + id + '/delete',
        { headers: headers }
      )
      .pipe(
        map((response: Responsemodel) => { return response }),
        catchError((error: HttpErrorResponse) => this.errorHelper.handleError(error))
      );
  }

  isUserLoggedIn(): boolean {
    if (localStorage.getItem('accessToken') === null && localStorage.getItem('currentUserId') === null) {
      return false;
    }
    else {
      return true;
    }
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    // localStorage.removeItem('role');
    localStorage.removeItem('user');
    // localStorage.removeItem('profpic');
    localStorage.removeItem('eyeProb');
  }

  removePatientInfo() {
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('profpic');
  }

}
