import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './core/layout/layout.component';
import { LoginComponent } from './core/login/login.component';
import { PagenotfoundComponent } from 'app/core/pagenotfound/pagenotfound.component';

import { UserloggedinguardGuard } from 'app/services/auth/userloggedinguard.guard';
import { AdminguardGuard } from 'app/services/auth/adminguard.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [AdminguardGuard],
    children: [
      {
        path: '',
        loadChildren: 'app/modules/dashboard/dashboard.module#DashboardModule',
        // pathMatch: 'full'
      },
      {
        path: 'patient/add',
        loadChildren: 'app/modules/patient/addpatient/addpatient.module#AddpatientModule',
      },
      {
        path: 'patient/list',
        loadChildren: 'app/modules/patient/patientlist/patientlist.module#PatientlistModule',
      },
      {
        path: 'generalinfo',
        loadChildren: 'app/modules/generalinfo/generalinfo.module#GeneralinfoModule',
      },
      {
        path: 'checkup',
        loadChildren: 'app/modules/checkup/checkup.module#CheckupModule',
      },
      // {
      //   path: 'appointment',
      //   loadChildren: 'app/modules/appointment/appointment.module#AppointmentModule'
      // },
      {
        path: 'medhistory',
        loadChildren: 'app/modules/medicalhistory/medicalhistory.module#MedicalhistoryModule'
      },
      {
        path: 'findings',
        loadChildren: 'app/modules/findings/findings.module#FindingsModule'
      },
      {
        path: 'treatment',
        loadChildren: 'app/modules/treatment/treatment.module#TreatmentModule'
      },
      {
        path: 'treatmentsales',
        loadChildren: 'app/modules/treatmentsales/treatmentsales.module#TreatmentsalesModule'
      },
      {
        path: 'labresults',
        loadChildren: 'app/modules/labresultsdiag/labresultsdiag.module#LabresultsdiagModule'
      },
      {
        path: 'medicalabstract',
        loadChildren: 'app/modules/medicalabstract/medicalabstract.module#MedicalabstractModule'
      },
      {
        path: 'medicalcertificate',
        loadChildren: 'app/modules/medicalcertificate/medicalcertificate.module#MedicalcertificateModule'
      },
      {
        path: 'treatmentplan',
        loadChildren: 'app/modules/treatmentplan/treatmentplan.module#TreatmentplanModule'
      },
      {
        path: 'documents',
        loadChildren: 'app/modules/documents/documents.module#DocumentsModule'
      },
      {
        path: 'users',
        loadChildren: 'app/modules/user/user.module#UserModule'
      },
      {
        path: 'diagnosis',
        loadChildren: 'app/modules/diagnosis/diagnosis.module#DiagnosisModule'
      },
      {
        path: 'treatment-plans',
        loadChildren: 'app/modules/treatment-plan/treatment-plan.module#TreatmentPlanModule'
      },
      {
        path: 'treatment-plans-tpl',
        loadChildren: 'app/modules/treatment-plan-template/treatment-plan-template.module#TreatmentPlanTemplateModule'
      },
      {
        path: '**',
        component: PagenotfoundComponent,
        canActivate: [UserloggedinguardGuard]
      }
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {
}