import { Component } from '@angular/core';
import { SidenavItem } from './core/sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from './core/sidenav/sidenav.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(sidenavService: SidenavService) {
    const menu: SidenavItem[] = [];

    menu.push({
      name: 'Dashboard',
      routeOrFunction: '/admin',
      icon: 'dashboard',
      position: 10,
      pathMatchExact: true
    });

    const patientlist = {
      name: 'Patients List',
      routeOrFunction: '/admin/patient/list',
      position: 10
    };

    const addpatient = {
      name: 'Add New Patient',
      routeOrFunction: '/admin/patient/add',
      position: 15
    };

    menu.push({
      name: 'Patients',
      icon: 'people',
      position: 10,
      subItems: [
        patientlist,
        addpatient
      ]
    });

    const generalinfo = {
      name: 'General Info',
      routeOrFunction: '/admin/generalinfo',
      position: 10
    };

    // const appointments = {
    //   name: 'Appointments',
    //   routeOrFunction: '/admin/appointment',
    //   position: 15
    // };

    const checkup = {
      name: 'Checkup',
      routeOrFunction: '/admin/checkup',
      position: 15
    };

    const medHistory = {
      name: 'Medical History',
      routeOrFunction: '/admin/medhistory',
      position: 15
    };

    // const documents = {
    //   name: 'Documents',
    //   routeOrFunction: '/admin/documents',
    //   position: 30
    // };

    const findings = {
      name: 'Findings',
      routeOrFunction: '/admin/findings',
      position: 20
    };

    const treatments = {
      name: 'Treatments',
      routeOrFunction: '/admin/treatment',
      position: 25
    };

    // const treatmentPlan = {
    //   name: 'Treatment Plan',
    //   routeOrFunction: '/admin/treatmentplan',
    //   position: 30
    // };

    const labResultsDiag = {
      name: 'Lab Results & Diag',
      routeOrFunction: '/admin/labresults',
      position: 30
    };

    menu.push({
      name: 'Patient Information',
      icon: 'info',
      position: 10,
      subItems: [
        generalinfo,
        // appointments,
        checkup,
        medHistory,
        // documents,
        findings,
        // treatments,
        labResultsDiag
      ]
    });

    const treatmentList = {
      name: 'Treatment List',
      routeOrFunction: '/admin/treatment',
      position: 10
    };

    const users = {
      name: 'Users',
      routeOrFunction: '/admin/users',
      position: 15
    };

    const diagnosis = {
      name: 'Diagnosis',
      routeOrFunction: '/admin/diagnosis',
      position: 16
    };

    const treatmentplans = {
      name: 'Treatment Plans',
      routeOrFunction: '/admin/treatment-plans',
      position: 17
    };

    const treatmentplansTpl = {
      name: 'Treatment Plans Template',
      routeOrFunction: '/admin/treatment-plans-tpl',
      position: 18
    };

    menu.push({
      name: 'Settings',
      icon: 'settings',
      position: 10,
      subItems: [
        treatmentList,
        users,
        diagnosis,
        treatmentplans,
        treatmentplansTpl
      ]
    });

    const medicalAbstract = {
      name: 'Medical Abstract',
      routeOrFunction: '/admin/medicalabstract',
      position: 10
    };

    const medicalCertificate = {
      name: 'Medical Certificate',
      routeOrFunction: '/admin/medicalcertificate',
      position: 15
    };

    menu.push({
      name: 'Forms',
      icon: 'settings',
      position: 10,
      subItems: [
        medicalAbstract,
        medicalCertificate
      ]
    });

    const treatmentSales = {
      name: 'Treatment Sales',
      routeOrFunction: '/admin/treatmentsales',
      position: 10
    };

    menu.push({
      name: 'Sales Record',
      icon: 'settings',
      position: 10,
      subItems: [
        treatmentSales
      ]
    });

    // Send all created Items to SidenavService
    menu.forEach(item => sidenavService.addItem(item));
  }
}
