import { Injectable } from '@angular/core';

import { Patientviewinfomodel } from 'app/models/patientviewinfomodel';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  private patientModel: Patientviewinfomodel[];

  constructor() { }

  addPatient(patientInfo: Patientviewinfomodel) {
    this.patientModel.push(patientInfo);
  }

  getPatients(): Patientviewinfomodel[] {
    return this.patientModel;
  }

}
