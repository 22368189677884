import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../common/material-components.module';

import { MediaQueryService } from '../common/mediareplay/media-replay.service';

import { StartupComponent } from './startup.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [StartupComponent],
  exports: [StartupComponent],
  providers: [MediaQueryService]
})
export class StartupModule {
}
