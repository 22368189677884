import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

import { AccountService } from 'app/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AdminguardGuard implements CanActivate {
// CanActivateChild
  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isLoggedIn = this.accountService.isUserLoggedIn();
    // let role = parseInt(localStorage.getItem('role')); // Preparatory variable for role base access level

    if (isLoggedIn === true)
      return true;
    else {
      this.router.navigateByUrl('login');
      return false;
    }

    // if (isLoggedIn === true && (role === 1 || role === 2) || isLoggedIn === true)
    //   return true;
    // else {
    //   this.router.navigateByUrl('login');
    //   return false;
    // }
  }

  // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
  //   let isLoggedIn = this.accountService.isUserLoggedIn();
  //   let role = parseInt(localStorage.getItem('role')); // Preparatory variable for role base access level
    
  //   if (isLoggedIn === true && (role === 1 || role === 2) || isLoggedIn === true)
  //     return true;
  //   else {
  //     this.router.navigateByUrl('login');
  //     return false;
  //   }
  // }

}
